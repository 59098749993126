<template>
    <div style="background-color: white;">
        <div class="logins_nav">
			<div>
				<img class="cursor" src="@/assets/image/bufanlogo.png" style="margin-right: 36px;" alt="" @click="toUrl('/')">
				欢迎登录
			</div>
			<div>
				<img src="@/assets/image/login_phone.png" alt="">
				0769-82810622
			</div>
        </div>
                 <!-- 微信支付方式 -->
        <div class="pop_payment" style="z-index:99;" v-if="wechatStauts" @click.stop>
            <div class="wechat_pay_main">
                <div class="ewm_login">
                    <div id="login_container"></div>
                </div>
                 <!-- <qrcode-vue :value="wechatText.pay_data" :size="200" level="H" /> -->
                <div class="off_wechat_pay" @click="wechatStautsBtn">
                    取消
                </div>
            </div>        
        </div>
		<div class="login_banner">
			<div class="logins_boxs">
				<div class="logins_right_bj">
					<div class="title">
						<div :class="active == index ? 'actives' : ''" v-for="(item,index) in options" :key="index"
							@click="loginStatusFun(index)">
							{{ item.label }}
						</div>
					</div>
					<div class="logins_inputs">
						<!-- <div>
							<select v-model="value" class="selects" placeholder="Select" size="large">
								<option
								v-for="item in options"
								:key="item.value"
								:label="item.label"
								:value="item.value"
								></option>
							</select>
					   </div> -->
						<div class="input_text" v-if="active == 0">
							<input type="text" placeholder="请输入手机号" v-model="phone">
						</div>
						<div class="input_text" v-if="active == 1">
							<input type="text" placeholder="请输入邮箱" v-model="postbox">
						</div>
						<div class="input_logo" @click="wordStatus">
							<img src="@/assets/image/peop.png" alt="">
						</div>
					</div>
					<div class="logins_inputs">
						<div class="input_texts">
							<input :type="wordtext" placeholder="请输入密码" v-model="passWord">
						</div>
						<div class="input_logo" @click="wordStatus">
							<img v-if="checked_status" src="@/assets/icon/faxiangengduo.png" alt="">
							<img v-if="!checked_status" src="@/assets/icon/请输入密码icon.png" alt="">
						</div>
					</div>
					<div class="logins_btn_submit" @click="loginFun">
						登  录
					</div>
					<div class="logins_text f12">
						<div style="text-align:right;width:100%;">
							<a @click="toUrl('/modify')"> 忘记密码？</a>
						</div>
					</div>
					<div class="logins_text">
						<div class="logins_register" @click.stop = "wechatStautsLogin">
							<img src="@/assets/icon/微信登录icon.png" alt="">
							<a>微信登录</a>
						</div>
						<div>
							<span>还没有账号？</span>&nbsp;&nbsp;
							<a @click="toUrl('/register')">免费注册</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pop_box" v-if="loginWicth">
			<div class="pop_boxs">
				<div class="title">请选择登录的系统</div>
				<div class="btn_shop" @click.stop="router.push({ path: '/' })">登录不凡商城</div>
				<div class="btn_model" @click.stop="router.push({ path: '/model_home' })">登录选型中心</div>
			</div>
		</div>
		<!-- 尾部 -->
		<footers></footers>
    </div>
</template>

<style src="./login.css" scoped>

</style>

<script setup>
import QrcodeVue from 'qrcode.vue';
import { ref,reactive,watch,toRefs,getCurrentInstance,onMounted,onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { Toast, Dialog } from 'vant';
import { httpGet, httpPost, setAuthorization } from '@/utils/request';
import { useStore } from 'vuex';
import footers from '@/components/footer.vue';

const store = useStore();
const router = useRouter();
const toUrl = function (url) {
    router.push({ path: url })
};
onMounted(() => {
    window.addEventListener('keydown', keyDown)
})
const keyDown = (e) => {
    console.log(e.keyCode)
  //如果是回车则执行登录方法
  if (e.keyCode == 13) {
    loginFun()
  }
}
onUnmounted(() => {
  window.removeEventListener('keydown', keyDown, false)
})


const mailVerify = /^.+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;  //邮箱验证
const passWord = ref(''); //登录密码
const phone = ref(''); //登录账户
const postbox = ref('');  //登录邮箱
// 登录密码显示
let wordtext = ref('password');//文本显示密码内容
let checked_status = ref(false);
const wordStatus = () => {
    checked_status.value = !checked_status.value;
    if (checked_status.value) {
        wordtext.value = 'text';
    } else {
        wordtext.value = 'password';
    }
};

// 开启微信登录状态
let wechatStauts = ref(false);
const wechatStautsBtn = () => {
    wechatStauts.value = false;
}
const wechatStautsLogin = () => {
    wechatStauts.value = true;
    wechatLogin()
}

// 微信登录
const wechatLogin = async () => {
      // 从后端获取appid以及重定向地址
        let { data, code } = await httpGet('api/user/getappid');
        let url = encodeURIComponent(`http://www.a-bf.cn/#/login/loginMiddle`)
        // console.log(url)
        if (code == 0) return
        // 官方文档提供的实例对象,具体参数说明可往下看
        var obj = new WxLogin({
            self_redirect: false,
            id: 'login_container',
            appid: data.appid, 
            scope: 'snsapi_login',
            // sandbox:"allow-scripts allow-top-navigation allow-same-origin",
            redirect_uri:url, // 这里跳的是扫码成功后,账户操作的地址   
            style: 'black', 
            state:200
        }) 
        
}


//登录方式
const active = ref(0);
const loginStatusFun = (index) => {
    active.value = index
}

let loginWicth = ref(false)
//登录接口
const loginFun = (code) => {
	let data = {
	    password: passWord.value
	}
	if(typeof(code)!='string'){
		if (active.value == 0) {
		    if(phone.value == '') return Toast('请输入手机号')
		    data.account = phone.value
		}
		if (active.value == 1) {
		    if (!mailVerify.test(postbox.value)) return Toast('邮箱格式不正确');
		    data.account = postbox.value
		}
		if (data.password == '') return Toast('请输入密码')
	}else{
		data.code = code
	}
    httpPost('/api/user/login', data).then(res => {
        const { data } = res
        console.log(data, 'data')
        if (res.code == 0) return Toast(res.msg);
        if (res.code == 1) {
            store.commit('updateUserInfo', data.userinfo)
            store.commit('updateToken', data.userinfo.token)
            setAuthorization(data.userinfo.token)
            Toast(res.msg);
			if(res.data.userinfo.is_salesman==1){
				loginWicth.value = true
			}else{
				router.push({ path: '/' })
			}
        }
    })
    // Dialog.confirm({
    //     title: '确认登录'
    // }).then(() => {

    // })
    // .catch(() => {
    //     Toast('取消当前登录')
    // });
}
const getCode = () => {
	if(location.href.indexOf('code=')!=-1){
		loginFun(location.href.split('code=')[1])
	}
}
getCode()
const options = [
    {
        value: '0',
        label: '手机登录',
    },
    {
        value: '1',
        label: '邮箱登录',
    }
];
</script>
	<style scoped>
		#app{
			background-color: white !important;
		}
		body{
			background-color: white !important;
		}
		.logins_nav{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 34px 367px 20px;
			height: 107px;
			font-size: 20px;
		}
		.logins_nav div:nth-of-type(2){
			color: #BB0111;
			font-size: 24px;
		}
		.logins_nav div:nth-of-type(2) img{
			width: 58px;
			height: 58px;
		}
	</style>